import mutations from "./mutations";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";

const addNotificationSuccess = () => {
  notification.success({
    message: "Record added successfully",
  });
};
const updateNotificationSuccess = () => {
  notification.success({
    message: "Record updated successfully",
  });
};
const deleteNotificationSuccess = () => {
  notification.success({
    message: "Record deleted successfully",
  });
};
const restorNotificationSuccess = () => {
  notification.success({
    message: "Record restored successfully",
  });
};

const notificationError = (err) => {
  notification.error({
    message: err,
  });
};

const state = () => ({
  data: [],
  product: null,
  saleId: null,
  saleNumber: "",
  url: null,
  saleConfirmed: false,
  saleIsValid: true,
  fileLoading: false,
  loading: false,
  error: null,
});

const actions = {
  async saleSubmitData({ commit }, data) {
    try {
      await commit("saleApiAddBeginBegin");
      const response = await DataService.post("api/sale", data.formState);
      await commit("saleApiAddBeginSuccess", response.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("saleApiAddBeginErr", err.Message);
    }
  },
  async saleChangeType({ commit }, data) {
    try {
      await commit("saleApiAddBeginBegin");
      const response = await DataService.post("api/sale/ChangeSaleType", data);
      await commit("saleApiAddBeginSuccess", response.data.data);
      addNotificationSuccess();
    } catch (err) {
      await commit("saleApiAddBeginErr", err.Message);
    }
  },
  async saleGenerateNumber({ commit }, data) {
    try {
      await commit("saleApiAddBeginBegin");
      const response = await DataService.get(
        `api/sale/GetNewSaleNumber?saleType=${data.saleType}&Year=${data.Year}`
      );
      await commit("saleApiGenerateSuccess", response);
    } catch (err) {
      await commit("saleApiAddBeginErr", err.Message);
      notificationError(err.Message);
    }
  },

  async singleSaleGetData({ commit }, id) {
    try {
      await commit("saleApiReadBegin");
      const query = await DataService.get("api/sale/" + id);
      await commit("saleApiReadSuccess", query.data);
      await commit("saleProductApiReadSuccessFromApi", query.data.products);
    } catch (err) {
      await commit("saleApiDeleteErr", err.Message);
    }
  },
  async singleSaleOnlyGetData({ commit }, id) {
    try {
      await commit("saleApiReadBegin");
      const query = await DataService.get("api/sale/" + id);
      await commit("saleApiReadSuccess", query.data);
    } catch (err) {
      await commit("saleApiDeleteErr", err.Message);
    }
  },

  async getSaleGetValidity({ commit }, id) {
    try {
      const query = await DataService.get("/api/sale/CheckSaleIsValidAsync?saleId=" + id);
      await commit("saleUpdateValiditySuccess", query.data);
    } catch (err) {
      await commit("saleUpdateValidityError", err.Message);
    }
  },

  async validateInvoice({ commit }, id) {
    try {
      const query = await DataService.put("/api/sale/Validate?id=" + id);
      if (query.succeeded) {
        await commit("saleUpdateValiditySuccess", query.data);
        updateNotificationSuccess();

      }
    } catch (err) {
      await commit("saleUpdateValidityError", err.Message);
    }
  },

  async saleGetAllDeletedData({ commit }, pagination) {
    try {
      await commit("saleApiReadBegin");
      const query = await DataService.get(
        `api/Sale/GetDeletedItem?SaleType=${pagination.saleType}&PartnerId=${pagination.salePartenaire}&SaleDateFrom=${pagination.saleDateFrom}&SaleDateTo=${pagination.saleDateTo}&Number=${pagination.saleNumber}&PageSize=100000`
      );
      await commit("saleApiReadSuccess", query.data);
    } catch (err) {
      await commit("saleApiReadErr", err.Message);
    }
  },
  async saleGetAllData({ commit }, pagination) {
    try {
      await commit("saleApiReadBegin");
      const query = await DataService.get(
        `api/Sale?SaleType=${pagination.saleType}&PartnerId=${pagination.salePartenaire}&SaleDateFrom=${pagination.saleDateFrom}&SaleDateTo=${pagination.saleDateTo}&Number=${pagination.saleNumber}&HasAcknowledgement=${pagination.hasAcknowledgement ?? ``}&AddFiles=${pagination.addFiles ?? false}&PageSize=100000`
      );
      await commit("saleApiReadSuccess", query.data);
    } catch (err) {
      await commit("saleApiReadErr", err.Message);
    }
  },

  async saleInfo({ commit }) {
    try {
      await commit("saleApiReadBegin");
      const query = await DataService.get(
        `api/Partenaire?OrderBy=name&Fields=id%2Cname&PageSize=10000`
      );
      await commit("saleApiReadSuccess", query.data);
    } catch (err) {
      await commit("saleApiReadErr", err.Message);
    }
  },

  async saleUpdateData({ commit }, data) {
    try {
      await commit("saleApiUpdateBegin");
      var response = await DataService.put(`api/sale/${data.formState.id}`, data.formState);
      if (!response.succeeded) {
        throw response.exception;
      }
      await commit("saleApiUpdateBeginSuccess", data.formState);
      updateNotificationSuccess();
    } catch (err) {
      await commit("saleApiUpdateErr", err);
      if (err.Message) {
        notificationError(err.Message);
      }
    }
  },
  async addCustomerPo({ commit }, data) {
    try {
      await commit("saleApiUpdateBegin");
      var response = await DataService.put(`api/sale/addCustomerPo`, data);
      if (!response.succeeded) {
        throw response.exception;
      }
      let result = {
        saleStatus: 2
      }
      await commit("saleApiUpdateBeginSuccess", result);
      updateNotificationSuccess();
    } catch (err) {
      await commit("saleApiUpdateErr", err);
      if (err.Message) {
        notificationError(err.Message);
      }
    }
  },

  async saleApiDataSearch({ commit }, searchItem) {
    try {
      await commit("saleApiReadBegin");
      if (searchItem != "") {
        const query = await DataService.get(`/search/${searchItem}`);
        await commit("saleApiReadSuccess", query.data);
      } else {
        try {
          const query = await DataService.get("/view-all");
          await commit("saleApiReadSuccess", query.data);
        } catch (err) {
          await commit("saleApiReadErr", err.Message);
        }
      }
    } catch (err) {
      await commit("saleApiReadErr", err.Message);
    }
  },

  async saleApiDataDelete({ commit }, { id }) {
    try {
      await commit("saleApiDeleteBegin");
      await DataService.delete(`/api/sale/${id}`);
      await commit("saleApiDeleteSuccess", id);
      deleteNotificationSuccess();
    } catch (err) {
      await commit("saleApiDeleteErr", err.Message);
      notificationError(err.Message);
    }
  },
  async saleApiDataRestore({ commit }, { id }) {
    try {
      await commit("saleApiDeleteBegin");
      await DataService.put(`/api/sale/RecoverDeletedItem/${id}`, { saleId: id });
      await commit("saleApiDeleteSuccess", id);
      restorNotificationSuccess();
    } catch (err) {
      await commit("saleApiDeleteErr", err.Message);
      notificationError(err.Message);
    }
  },
  async emptySale({ commit }) {
    await commit("emptySaleData");
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
